export const SEND_PAGE_VIEW = 'GOOGLE_ANALYTICS/SEND_PAGE_VIEW'

// These properties are not specifically in the codebase, they are generated from the named modal
export const IMPORT_CONTACTS_CONSENT_YES = 'MODAL_import_contacts_importConsent_SUBMIT'
export const IMPORT_CONTACTS_CONSENT_NO = 'MODAL_import_contacts_importConsent_CLOSE'

export const SEND_EVENT_4 = 'GOOGLE_ANALYTICS/SEND_EVENT_4'
export const SEND_EVENT_COMPLETE = 'GOOGLE_ANALYTICS/SEND_EVENT_COMPLETE'

export const UPGRADE_FROM = 'upgradeFrom'
export const UPGRADE_ITEM = 'upgradeItem'

export const EventNames = {
  AddSource: 'add_content_source',
  ChatGPTGenerate: 'chat_gpt_generate',
  ChatGPTOpen: 'chat_gpt_open',
  ChatGPTSave: 'chat_gpt_save',
  Checkout: 'begin_checkout',
  CompletePurchase: 'purchase',
  ContentTool: 'select_content_tool',
  ImportConsent: 'import_consent',
  OpenContentTool: 'open_content_tool',
  PickSource: 'pick_content_source',
  TRexLaunch: 't_rex_launch',
  TRexQuestion: 't_rex_question',
  TRexAddArticle: 't_rex_add_article',
  Upgrade: 'select_promotion',
}

export const UpgradeSource = {
  AddNewsletter: 'add_newsletter',
  AddSection: 'add_section',
  AddSource: 'add_source',
  AddTopicSource: 'add_topic_source',
  AddUser: 'add_user',
  CsvContactLimit: 'csv_contact_limit',
  CsvMessageLimit: 'csv_message_limit',
  CustomTemplate: 'custom_template',
  DomainAuthentication: 'domain_authentication',
  FooterLogo: 'footer_logo',
  HubSpot: 'hubspot',
  Integrations: 'integrations',
  LayoutModules: 'layout_modules',
  ScheduledContent: 'scheduled_content',
  UsageLimit: 'usage_limit',
  UsageWidget: 'usage_widget',
  UseSection: 'use_section',
  WeeklyReport: 'weekly_report',
}
